const AkRelationDataTable = {
    data: function () {
        return {
            idsSelected: this.defaultIdsSelected
        }
    },
    props: {
        type: {
            type: String,
            required: true
        },
        definition: {
            type: String,
            required: true
        },
        bundle: {
            type: String,
            required: true
        },
        relation: {
            type: String,
            default: '',
            required: true
        },
        childRelationString: {
            type: String,
            required: false
        },
        defaultIdsSelected: {
            type: Array,
            required: false,
            default: []
        }
    },
    computed: {
        queryForRelationTable() {
            return {
                originalObjectId: this.$route.params.objectId
            }
        },
        isListView() {
            return this.type === 'list';
        }
    },
    watch: {
        childRelationString: function(childRelationString) {
            //check if the relationID is updated
            //when we don't have a child relationId we are the active relationGrid so we refresh because a save took place
            if(childRelationString == undefined && this.$refs.dataTable) {
                this.$refs.dataTable.refresh();
                this.$refs.dataTable.refreshParam++;
            }
        }
    },
    methods: {
        updateSelection(selectedIds) {
            this.idsSelected = selectedIds;
        },
        refresh() {
            this.$emit('refresh', this.defaultIdsSelected);
        },
        save () {
            this.$emit('save', this.idsSelected);
            this.$emit('close');
        },
        cancel () {
            this.$emit('close');
        },
        updateRelationString(data) {
            let params = this.$route.params;
            // add the formDefinition & object Id to the relation ID
            params.relationString = params.relationString + ':form:'+ data.bundleId + ':' + data.definitionId + ':' + data.objectId;

            //redirect to the new relation state
            this.$router.push({ name: this.$route.name , params: params, query: this.$route.query});
        },
        /**
         * Trigger close
         */
        triggerClose() {
            this.$router.back();
        }
    }
};

Vue.asyncComponent('ak-relation-data-table', AkRelationDataTable , 'grid/ak-relation-data-table.html');
export default AkRelationDataTable;