Vue.asyncComponent('ak-menu-item', {
    name: '',
    data: () => {
        return {
            childMenuOpen: false,
            childItemActive: false,
            active: false,
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: false,
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        },
        isSubMenu: {
            type: Boolean,
            required: false,
            default: false
        },
        parentSearchString: {
            type: String,
        },
        showChildren: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
        },
        badgeLabel: {
            type: String,
            required: false,
        },
        collapsedStates: {
            type: Object,
        }
    },
    computed: {
        isFullUrl() {
            return this.href.substring(0, 4) === 'http';
        },
        hasChildMenu() {
            return (this.childMenu() != undefined || (this.items && this.items.length))
        },
        /**
         * Collapsed state id
         * @returns {string}
         */
        collapsedStateId() {
            return `ak-menu-${this.title}-${this.href}`;
        }
    },
    watch: {
        '$route': {
            handler() {
                this.childItemActive = false;
                this.checkActive();
            },
            deep: true
        }
    },
    methods: {
        childMenu() {
            return this.$children.find(
                child => child.$options.name === "ak-menu"
            );
        },
        onClickMenuItem(event) {
            let childMenu = this.childMenu(this);

            if (childMenu) {
                this.childMenuOpen = !this.childMenuOpen;
                this.setCollapsedState(this.childMenuOpen);

                childMenu.show = !childMenu.show;
                if(event) {
                    event.preventDefault();
                }

                return;
            }

            if (this.href) {
                this.$actionHandler.handleRoute({route:this.href, target:this.target});
            }
            this.$emit('click');
        },
        /**
         * Update the collapsed state id for the current menu item
         * @param state
         */
        setCollapsedState(state) {
            const states = localStorage.getItem(`ak-menu-collapsed-state`) ?
                JSON.parse(localStorage.getItem(`ak-menu-collapsed-state`)):
                {};

            // update the state object
            if (state) {
                states[this.collapsedStateId] = true;
            } else {
                delete states[this.collapsedStateId];
            }

            localStorage.setItem(`ak-menu-collapsed-state`, JSON.stringify(states));
        },
        checkActive() {
            if (this.isFullUrl) {
                this.active =  window.location.href === this.href;
            } else {
                this.active =  this.$route.path == this.href;
            }

            if (this.active) {
                this.$emit('isActive', this.active);
            }
        },
        setChildItemActive() {
            this.childItemActive = true;
        },
        /**
         * Set child menu show
         * @param show
         */
        setChildMenuShow(show) {
            this.$nextTick(() => {
                const childMenu = this.childMenu();

                // we keep trying until we have a child menu if we have child items
                if (! childMenu && this.items) {
                    this.setChildMenuShow(show);
                    return;
                }

                childMenu.show = show;
            });
        }
    },
    mounted() {
        this.checkActive();

        if( ! this.isSubMenu && this.collapsedStates && this.collapsedStates[this.collapsedStateId]) {
            this.childMenuOpen = true;
            this.setChildMenuShow(this.childMenuOpen);
        }
    }
}, 'layout/ak-menu-item.html');
