Vue.asyncComponent('ak-mobile-table-card', {
    data() {
        return {
            showColumns: false
        }
    },
    props: {
        dragId: {
            type: String,
        },
        dragging: {
            type: String,
        },
        row: {
            type: Object,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        rowKey: {
            type: Number,
            required: true
        },
        selected: {
            type: Boolean,
        },
        sortingEnabled: {
            type: Boolean,
        },
        selectionEnabled: {
            type: Boolean,
        },
        widgetCallbackUri: {
            type: String,
            required: true
        },
        paramsForCallback: {
            type: Object,
            required: false,
            default: () => {}
        },
        contextualTitle: {
            type: String,
            required: false
        }
    },
    computed:{
        /**
         * Get the first column propperty
         *
         * @returns {*}
         */
        titleColumnProp() {
            return Object.keys(this.row.columns)[0];
        },
        /**
         * We use the first column as the title column for the card
         *
         * @returns {*}
         */
        titleColumn() {
            return this.row.columns[this.titleColumnProp];
        },
        /**
         * We return all other columns but the first
         * @returns {*}
         */
        leftOverColumns() {
            const columns = {};

            if (this.showColumns) {
                Object.keys(this.row.columns).forEach(key => {
                    if (key !== this.titleColumnProp) {
                        columns[key] = this.row.columns[key];
                    }
                });
            }

            return columns;
        }
    },
    methods: {
        /**
         * Handle edit
         * @param data
         */
        handleEdit(data) {
            this.$emit('edit', data);
        },
        /**
         * Handle delete
         * @param data
         */
        handleDelete(data) {
            this.$emit('delete', data);
        },
        /**
         * Trigger refresh
         */
        triggerRefresh() {
            this.$emit('refresh');
        },
        /**
         * A widget is changed
         * @param objectId
         */
        widgetChanged({objectId}) {
            this.$emit('widgetChanged', objectId);
        },
        /**
         * Toggle select if enabled
         */
        toggleSelect() {
            if (! this.selectionEnabled) {
                return;
            }

            if (this.selected) {
                this.deselectRow();
            } else {
                this.selectRow();
            }
        },
        /**
         * Select Row
         */
        selectRow() {
            this.$emit('select', this.row.id);
        },
        /**
         * DeselectRow
         */
        deselectRow() {
            this.$emit('deselect', this.row.id);
        },
        /**
         * Get the column title by key
         * @param key
         * @returns {*}
         */
        findColumnTitle(key) {
            return this.columns.find(x => x.id === key).title;
        },
        /**
         * Get the column formatting
         * @param key
         * @param value
         * @returns {*}
         */
        applyColumnFormatting(key, value) {
            const formatting = this.columns.find(x => x.id === key).formatting;

            // if there is no formatting set in for this value just return the value
            if(typeof formatting == 'undefined') {
                return value;
            }

            // else we apply the formatting
            return this.$applyFormatting(value, formatting);
        },
        /**
         * Toggle the leftover columns
         */
        toggleShowMore() {
            this.showColumns = !this.showColumns;
        }
    }

}, 'grid/ak-mobile-table-card.html');