Vue.asyncComponent('ak-menu', {
    name: 'ak-menu',
    data() {
        return {
            show: true,
            search: null,
            collapsedStates: {},
        }
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => {},
        },
        searchEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        parentSearchString: {
            type: String,
            required: false,
        },
        showChildren: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    computed: {
        isSubMenu() {
            let isSubMenu = false;
            let parent = this.$parent;
            
            while (parent) {
                if (parent && parent.$options.name == this.$options.name) {
                    isSubMenu = true;
                }

                parent = parent.$parent;
            }
            
            return isSubMenu;
        },
        currentItems() {
            if (! this.search && ! this.parentSearchString) {
                // reset the showChildren
                this.items.forEach(item => {
                    this.applySearch(item);
                });

                return this.items;
            }

            return this.items.filter(item => {
                return this.applySearch(item);
            })
        }
    },
    methods: {
        onClickReturn() {
            this.$emit("ak-click-return");
            this.show = false;
        },
        applySearch(item) {
            let result = false;
            if (( this.search && item.title.toLowerCase().includes(this.search.toLowerCase()))
                ||(this.parentSearchString &&  item.title.toLowerCase().includes(this.parentSearchString.toLowerCase()))) {
                result = true;
            }

            if (item.items) {
                let childMatched = false;

                item.items.forEach(childItem => {
                    if (this.applySearch(childItem)) {
                        result = true;
                        childMatched = true;
                    }
                });

                item.showChildren = childMatched;
            }
            return result;
        }
    },
    mounted() {
        this.show = !this.isSubMenu;

        if (! this.isSubMenu) {
            this.collapsedStates = localStorage.getItem(`ak-menu-collapsed-state`) ?
                    JSON.parse(localStorage.getItem(`ak-menu-collapsed-state`)):
                    {};
        }
    }
}, 'wrappers/ak-menu.html');