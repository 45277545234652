const AkDataWrapper = {
    props: {
        definition: {
            type: String,
            required: true
        },
        bundle: {
            type: String,
            required: true
        },
        infiniteScroll: {
            type: Boolean,
            required: false,
            default: false
        },
        idsSelected: {
            type: Array | Object,
            required: false,
            default: () => { return [] }
        },
        mockData: {
            type: Boolean,
            required: false,
            default: false
        },
        mockDataObject: {
            type: Object,
            required: false,
            default: () => { }
        },
        relation: {
            type: String,
            default: '',
            required: false
        },
        query: {
            type: Object,
            default: () => {},
            required: false
        },
        compactFilters: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            descr: '',
            numberOfTilesPerRow: null,
            pageJumper: {},
            pageJumperWidgets: {},
            dataObjects: [],
            filters: {},
            filterEdit: null,
            showFilterDialog: false,
            columns: [],
            selectables: {},
            baseGridUri: null,
            selection: {
                allSelected: false,
                idsSelected: [],
                idsExcluded: []
            },
            searchEnabled: false,
            dataFetchParams: {
                page: 1,
                q: '',
                sortby: '',
                direction: ''
            },
            searchString: '',
            preventAutoRefresh: false,
            debounceTimeout: null,
            isUpdating: true,
            updatingMessage: null,
            idleWidgets: {},
            contextualPageJumperWidgets: {},
            sortingEnabled: null,
            search: {},
            dragParam: 0,
            refreshParam: 0,
            maxTableHeight: null,
            noResultString: null,
            exportDefinition: null,
            exportBundle: null,
            canResetCookie: true,
            hasLocalizedProperties: false,
            initialized: false,
            showError: false,
            wheelEventEndTimeout: null,
        };
    },
    computed: {
        inViewPortKey() {
            return this.dataObjects[0].id + this.pageJumper.totalCount + this.tableHasBeenSorted + this.filters.applied.length + this.dragParam + this.refreshParam;
        },
        tableHasBeenSorted() {
            if (this.columns.findIndex(x => x.isSortedBy === true) === -1) return '0';
            else return '1';
        },
        showSearchDescription() {
            let show = false;
            if(! ('terms' in this.search)) {
                return show;
            }

            this.search.terms.forEach(item => {
                if(item.type != "text") {
                    show = true;
                }
            })

            return show;
        },
        searchDescription() {
            let description = '';
            this.search.terms.forEach(item => {
                description += item.descr.text + ' ';
            });

            return description;
        },
        addVariablesToDataFetchParams() {
            let allParams = { ...this.dataFetchParams };

            if (this.selection.allSelected) {
                allParams = { ...this.dataFetchParams, 'selectall': 1 }
            } else if (this.selection.idsSelected.length > 0) {
                allParams = { ...this.dataFetchParams, 'selectids' : this.selection.idsSelected };
            }

            if (this.filters.applied && this.filters.applied.length !== 0) {
                this.filters.applied.forEach((filter, i) => {
                    allParams[`f${i}`] = filter.id;
                    // [20220830] We loop through the values, instead of simply assigning them.
                    // This is to make sure that they have a key, instead of simply being added
                    // as an array element. This enables them to be able to overwrite any filters
                    // that are present in the query.
                    filter.values.forEach((value, key) => {
                        allParams[`v${i}[${key}]`] = value;
                    });
                });
            }

            if(this.relation) {
                allParams['relation'] = this.relation;
            }

            // [20220830] We apply the allParams second, to ensure that they will
            // overwrite any same filters that are applied in the query. For example,
            // filter on first name via the query, but when you change it via the
            // interface, it should be overwritten. This was not the case until now.
            allParams = {
                ...this.query,
                ...allParams,
                locale: this.$localeHandler.getActiveLocale()
            };

            return allParams;
        },
    },
    methods: {
        async refresh() {
            // on refresh, always go back to first page
            if (this.infiniteScroll) {
                this.dataFetchParams.page = 1;
            }

            const data = await this.fetchTableData();
            this.initialized = true;
            if (!data) return;

            this.columns = data.columns;
            this.pageJumper = data.pageJumper;
            this.title = data.title;
            this.descr = data.descr;
            this.searchEnabled = data.search.enabled;
            this.filters = data.filterUi;
            this.dataObjects = data.dataObjects;
            this.selectables = data.selectables;
            this.selection = data.selection;
            this.numberOfTilesPerRow = data.numberOfTilesPerRow;
            this.noResultString = data.noResultString;
            this.hasLocalizedProperties = data.hasLocalizedProperties;

            this.idleWidgets = data.widgets.idle;
            this.contextualPageJumperWidgets = data.widgets.contextualPageJumper;
            this.sortingEnabled = data.canSortNow;
            this.search = data.search;

            // because we always go to the first page when infinite scroll is active, scroll to top of page
            if (this.infiniteScroll) {
                window.scrollTo(0, 0);
            }

            this.setScrollPosition();
        },
        async fetchTableData(loadingMessage, path) {
			this.preventAutoRefresh = true;

            if (!path) path = this.baseGridUri;
            if (!loadingMessage) loadingMessage = this.$t('Gegevens laden');

            this.tellIfUpdating(true, this.$t(loadingMessage));
            const params = this.addVariablesToDataFetchParams;

            // When we're mocking data, just return the data object we're using to mock.
            if (this.mockData) {
                console.log('MOCKING DATA --- DEV MODE')
                this.tellIfUpdating(false);
                this.preventAutoRefresh = false;
                return this.mockDataObject;
            } else {
                // Otherwise, just get the new data
                try {
                    const data = await this.$get(path, params);
                    return data;
                } catch (error) {
                    this.showError = true;
                    console.log(error);
                } finally {
                    this.tellIfUpdating(false);
                    this.preventAutoRefresh = false;
                }
            }
        },
        async loadMore() {
            if (this.dataFetchParams.page === this.pageJumper.numberOfPages) return;

            this.preventAutoRefresh = true;
            this.dataFetchParams.page = this.dataFetchParams.page + 1;

            try {
                const data = await this.fetchTableData('Loading more');
                if (!data) return;
                this.dataObjects = [...this.dataObjects, ...data.dataObjects];
                this.pageJumper = data.pageJumper;
            } catch (error) {
                console.log(error);
            }

            this.preventAutoRefresh = false;
        },
        sortColumn(column) {

            if (!column.enableSortBy) return;
            if (this.dataFetchParams.sortby !== column.id) this.dataFetchParams.direction = '';
            this.dataFetchParams.page = 1;
            // First sort column ASC, then DESC and at the third click the sorting gets undone
            if (this.dataFetchParams.direction === '') {
                this.dataFetchParams.direction = 'ASC';
                this.dataFetchParams.sortby = column.id;
            }
            else if (this.dataFetchParams.direction === 'ASC') {
                this.dataFetchParams.direction = 'DESC';
                this.dataFetchParams.sortby = column.id;
            }
            else {
                this.dataFetchParams.direction = '';
                this.dataFetchParams.sortby = '';
            }
        },
        changePage(e) {
            this.dataFetchParams.page = e;
            localStorage.setItem(this.bundle + '-' + this.definition + '-scroll', 0);
            this.$refs['table-main'].scrollTo({top: 0, behavior:'smooth'});
        },
        clearSearch() {
            this.searchString = '';
            this.dispatchSearch();
        },
        dispatchSearch() {
            this.dataFetchParams = {
                page: 1,
                q: '',
                sortby: '',
                direction: ''
            };
            this.dataFetchParams.q = this.searchString;
        },
        tellIfUpdating(flag, message) {
            // Update the loading state at the bottom of the page
            this.isUpdating = flag;
            this.updatingMessage = message;
        },
        addFilter: function (filter) {
            // Check if the filter is already active
            let index = -1;
            if(this.filterEdit) {
                index = this.filters.applied.findIndex(filter => filter == this.filterEdit);
            }

            this.showFilterDialog = false;

            let clearQuery = false;
            // check if the edited filter is a filter from the url query
            for (let i = 0; i < (this.filters.applied.length + 1); i++) {
                if (this.query && filter.id == this.query['f' + i]) {
                    clearQuery = true;
                }
            }

            // Filter not yet active? Just push. Is it active? Replace the current one.
            if (index === -1) {
                this.filters.applied.push(filter);
            } else {
                this.filters.applied.splice(index, 1, filter);
            }

            // Set page back to 1 and refresh data
            this.dataFetchParams.page = 1;

            if (clearQuery) {
                // if so clear the query
                this.$emit('clearQuery');
                this.$nextTick(() => this.refresh());
            } else {
                this.refresh();
            }
        },
        editFilter(key) {
            const filter = this.filters.applied[key];

            this.filterEdit = filter;
            this.showFilterDialog = !this.showFilterDialog;
        },
        closeFilterDialog() {
            this.filterEdit = {},
                    this.showFilterDialog = false;
        },
        deleteFilter(key) {
            const filter = this.filters.applied[key];

            // check if the filter we wan't to delete exists in the query
            for (let i = 0; i < (this.filters.applied.length + 1); i++) {
                if (this.query && filter && filter.id == this.query['f' + i]) {
                    this.canResetCookie = false;
                    this.removeFilterFromQuery(i)
                }
            }

            this.filters.applied.splice(key, 1);

            this.dataFetchParams.page = 1;
            this.$nextTick(() => this.refresh());
        },
        deleteAllFilters() {
            this.filters.applied.forEach(filter => {
                // check if the filter we wan't to delete exists in the query
                for (let i = 0; i < (this.filters.applied.length + 1); i++) {
                    if (this.query && filter.id == this.query['f' + i]) {
                        this.removeFilterFromQuery(i)
                    }
                }
            })

            this.filters.applied = [];
            this.dataFetchParams.page = 1;

            this.$nextTick(() => this.refresh());
        },
        removeFilterFromQuery(i) {
            const newQuery = {...this.query};
            delete newQuery['f' + i];
            Object.keys(newQuery).forEach(key => {
                if(key.includes('v' + i)) {
                    delete newQuery[key];
                }
            });

            this.$emit('updateQuery', newQuery);
        },
        searchLiteral(text) {
            let string = this.search.query;
            string = string.replace(text, '"' + text + '"');

            this.searchString = string;
            this.dispatchSearch();
        },
        handleNew(data) {
            this.$emit('new', data);
        },
        handleEdit(data) {
            this.$emit('edit', data);
        },
        handleDelete(data) {
            // Make sure that a route call to itself just calls a refresh and doesn't reach Vue Router (where it will
            // throw an error)
            if (data.bundleId === this.bundle && data.definitionId == this.definition) {

                this.refresh();
            } else {
                // if not delete on itself then throw the delete event to parent
                this.$emit('delete', data);
            }
        },
        async submitNewSorting(e) {
            this.isUpdating = true;

            this.dragParam === 0 ? this.dragParam = 1 : this.dragParam = 0;

            // For a blue monday
            let newOrder = {
                instruction: e.instruction,
                startFromPage: this.infiniteScroll ? 1 : this.dataFetchParams.page,
                id: e.id
            };

            if (e.instruction === 'drag') newOrder.ids = this.dataObjects.map(x => x.id);

            const path = `${this.baseGridUri}sort-position`;
            const params = this.addVariablesToDataFetchParams;

            try {
                const result = await this.$post(path, newOrder, params);
                if (e.instruction !== 'drag') {
                    if (this.infiniteScroll) this.dataFetchParams.page = 1;
                    this.refresh();
                }
                if (result) this.$actionHandler.handle(result, this);
            } catch (error) {
                console.log(error);
            } finally {
                this.isUpdating = false;
            }
        },
        getParentStackedWindowFooter() {
            let parent = this.$parent;
            while (parent) {
                parent = parent.$parent;
                if (parent && parent.$el.querySelector('.ak-stacked-window__footer')) {
                    return parent.$el.querySelector('.ak-stacked-window__footer');
                }
            }
            return;
        },
        triggerExport(data) {
            // when we get a definition & bundle we will open a dialog with the export component
            // this component will de a batched export and provide a file to download
            this.exportDefinition = data.definitionId;
            this.exportBundle = data.bundleId;
        },
        // after the user closes/dowloads the document we reset the definition and bundle
        resetExport() {
            this.exportDefinition = null;
            this.exportBundle = null;
        },
        setFiltersFromCookie() {
            // first we check if we need to get persistent filters
            const persistentFilters = this.$cookies.get(this.bundle + '-' + this.definition);

            if (persistentFilters && (!this.query || this.query && Object.keys(this.query).length === 0)) {
                this.filters = {
                    applied: JSON.parse(persistentFilters)
                }
            }
        },
        handleQuery(newValue, oldValue) {
            if (! this.filters.applied) {
                return;
            }

            Object.keys(oldValue).forEach( key => {
                // if the filter doesen't exist anymore
                if( ! newValue[key] && ! key.includes('v')) {
                    // find the applied filter and remove it
                    const i = key.replace('f','');

                    const filterToRemove = this.filters.applied.findIndex( filter => {
                        if(filter.id === oldValue['f' + i] &&
                            filter.values[0] === oldValue['v' + i + '[0]'] &&
                            filter.values[1] === oldValue['v' + i + '[1]']) {
                            return filter;
                        }
                    });

                    if(filterToRemove === undefined) {
                        return;
                    }

                    this.deleteFilter(filterToRemove);
                }
            });
        },
        /**
         * @param rowId
         * @param columnId
         * @returns {Promise<void>}
         */
        async updateDataTableRow(rowId) {
            this.tellIfUpdating(true);

            const params = {
                id: rowId,
                locale: this.$localeHandler.getActiveLocale()
            };

            const result = await this.$get(`${this.baseGridUri}get-row`, params);
            const updatedRow = result.row;

            if (! updatedRow) {
                this.refresh();
            }

            // get the key of the row we need to update
            const rowKey = this.dataObjects.findIndex(row => row.id === updatedRow.id);

            if( rowKey === -1) {
                this.refresh();
            }

            // update the row data
            Vue.set(this.dataObjects, rowKey, updatedRow)
            //this.dataObjects[rowKey] = updatedRow;

            this.tellIfUpdating(false);
        },
        /**
         * Set the active locale and refresh the grid
         * @param lang
         */
        changeLocale(locale) {
            this.$localeHandler.setActiveLocale(locale);
            this.refresh();
        },
        /**
         * Update data objects
         * @param dataObjects
         */
        updateDataObjects(dataObjects) {
            this.dataObjects = dataObjects;
        },
        /**
         * Set fetch params from local storage
         *
         * If we have persistent filters enabled we have saved the dataFetchParams in the local storage
         * So here we check if we have fetch params in localStorage if so we will set them
         */
        setFetchParamsFromLocalStorage() {
            const tempDataFetchParams = localStorage.getItem(this.bundle + '-' + this.definition + '-fetch-params');

            if (tempDataFetchParams) {
                this.dataFetchParams = JSON.parse(tempDataFetchParams);
                this.searchString = this.dataFetchParams.q;
            }
        },
        /**
         * Set scroll position
         */
        setScrollPosition() {
            if (!this.isUpdating && localStorage.getItem(this.bundle + '-' + this.definition + '-scroll') != null) {
                this.$nextTick(() => {
                    setTimeout(() =>
                        this.$refs['table-main'].scrollTop = parseInt(localStorage.getItem(this.bundle + '-' + this.definition + '-scroll')),
                        500);
                });
            }
        },
        handleScroll() {
            localStorage.setItem(this.bundle + '-' + this.definition + '-scroll', this.$refs['table-main'].scrollTop);
        },
        resetScroll() {
            localStorage.setItem(this.bundle + '-' + this.definition + '-scroll', 0);
        }
    },
    watch: {
        dataFetchParams: {
            deep: true,
            handler() {
                if (this.preventAutoRefresh) return;
                if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
                // If we have persistent filters
                if (this.filters.persistent) {
                    // We keep the fetch params in storage
                    localStorage.setItem(this.bundle + '-' + this.definition + '-fetch-params', JSON.stringify(this.dataFetchParams));
                }
                this.debounceTimeout = setTimeout(() => this.refresh(), 300);
            }
        },
        "$route": {
            handler($route, $oldRoute) {
                this.baseGridUri = `/${this.bundle}/${this.definition}/`;
                if ($route.matched.length === 1) {
                    // this.dataFetchParams = {
                    //     page: 1,
                    //     q: '',
                    //     sortby: '',
                    //     direction: ''
                    // };
                    // this.filters = {};

                    // to prevent a race condition that causes filters to not be deleted after clicking the delete
                    // button we check this flag to see whether we are allowed to run the methods below
                    // we set this flag to true every time deleteFilter() is executed
                    // this race condition only appeared when we were dealing with query params in the url
                    if (this.canResetCookie) {
                        this.handleQuery($route.query, $oldRoute.query);
                        this.setFiltersFromCookie();
                        //this.refresh();
                    }

                    this.canResetCookie = true;
                    this.refreshParam++;
                }
            }
        },
        'filters.applied': {
            handler() {
                if(this.filters.persistent) {
                    // this.$cookies.set(this.bundle + '-' + this.definition, JSON.stringify(this.filters.applied));
                    this.$cookies.set(
                        this.bundle + '-' + this.definition,
                        JSON.stringify(this.filters.applied)
                    );
                }
            }
        },
        isUpdating: {
            handler(val) {
                this.$emit('loading', val);
            }
        },
        'selection.idsSelected': {
            handler() {
                // trow event when the selection is changed
                this.$emit('selectionChanged', this.selection.idsSelected);
            }
        }
    },
    created() {
        this.baseGridUri = `/${this.bundle}/${this.definition}/`;
        this.selection.idsSelected = this.idsSelected;

        this.setFiltersFromCookie();
        this.setFetchParamsFromLocalStorage();

        this.refresh();
    },
};
Vue.asyncComponent('ak-data-table', AkDataWrapper, 'grid/ak-data-wrapper.html');
Vue.asyncComponent('ak-data-wrapper', AkDataWrapper, 'grid/ak-data-wrapper.html');
